import React, { Component } from 'react';
import './App.css';
import Navbar from './components/navbar/navbar.js'

class App extends Component {
  
  render() {
    return (
      <Navbar />
    );
  }
}

export default App;

