import React, { Component } from 'react';
import "../../css/bootstrap.min.css";



class Welcome extends Component {

    render() {
        return (
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="jumbotron card card-block">
                                <h2 class="text-center">
                                Welcome To MyEZValet
                                </h2>
                                <div className="divider" />
                                <p class="text-justify">
                                    <br></br>
                                    MyEZValet is a way to simplify all the ins-and-outs of a valet parking business. We bring everything into one, easy to manage, application. MyEZValet allows you to manage your parkers and clients, schedule parking events and allow your parkers to signup, and allow your clients to schedule when they need you on their time. 
                                    <br></br><br></br>
                                    While we may still be under development, we urge you to signup for the latest updates we’re making and signup for early access which will give you free access to the application while it’s still under development and a provide you with a very low cost plan once we officially launch. 
                                    <br></br><br></br>
                                </p>
                                <p>
                                   <div className="beta_signup"><a class="btn btn-primary btn-large" href="mailto:contactus@myezvalet.com?Subject=Early%20Signup">Signup For Early Access</a></div> 
                                    <div className="divider"><br></br></div>
                                    <div className="progress_signup"><a class="btn btn-primary btn-large" href="mailto:progress@myezvalet.com?Subject=Keep%20Me%20Updated">Signup For Progress Updates</a></div>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
        );
    }
}

export default Welcome;