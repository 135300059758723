import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch} from "react-router-dom";

import Index from '../apps/index';

class Navbar extends Component {


    render() {
        return (
            <Router>
                <main>
                    <Switch>
                        <Route path="/"><Index/></Route>
                    </Switch>
                </main>
                <script src="../../js/jquery.min.js"></script>
                <script src="../../js/bootstrap.min.js"></script>
                <script src="../../js/scripts.js"></script>
            </Router>
        );
    }
}

export default Navbar;